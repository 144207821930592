import { render, staticRenderFns } from "./CitySelect.vue?vue&type=template&id=d51c58d0&scoped=true"
import script from "./CitySelect.vue?vue&type=script&lang=js"
export * from "./CitySelect.vue?vue&type=script&lang=js"
import style0 from "./CitySelect.vue?vue&type=style&index=0&id=d51c58d0&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d51c58d0",
  null
  
)

export default component.exports